import makeValidationSchema from './MakeValidationSchema'
import { components, constants, DateTimeFormatter, useTranslation } from 'cng-web-lib'
import React, { useState } from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Switch as MuiSwitch,
  Typography,
  withStyles,
  InputAdornment
} from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import SbciProductMasterAutoCompleteMasterField from '../../productmaster/autocomplete/SbciProductMasterAutoCompleteMasterField'
import OgaCodeSection from './OgaCodeSection'
import AddiInfoSection from './AddiInfoSection'
import Hts9802RepairSection from './Hts9802RepairSection'
import UsPackingSection from './UsPackingSection'
import GoodsReturnedSection from './GoodsReturnedSection'
import LaceyActSection from './LaceyActSection'
import FdaTabSection from './FdaTabSection'
import FccTabSection from './FccTabSection'
import ProduceSection from './ProduceSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import LineitemOgaCodeFormProperties from './OgaCodeFormProperties'
import LineitemAddiInfoFormProperties from './AddiInfoFormProperties'
import TemplateSelectDialog from '../../../../components/aciacehighway/TemplateSelectDialog'
import SbciProductMasterApiUrls from '../../../../apiUrls/SbciProductMasterApiUrls'

const {
  form: {
    field: {
      CngCheckboxField,
      CngCodeMasterAutocompleteField,
      CngTextField,
      CngUomAutocompleteField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
} = components

const { filter: { LIKE, EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveFlag: false,
  productId: "",
  lineItemId: "",
  invoiceId: "",
  partNo: "",
  fccTab: null,
  fdaTab: null,
  goodsreturned: null,
  hts9802Repair: null,
  htsCode: "",
  partDesc: "",
  partType: "",
  purchaseOrderNo: "",
  partRefNo: "",
  bindRulingIdType: "",
  bindRulingId: "",
  spclProgInd: "",
  unitValue: "",
  country: "",
  qty: "",
  qtyUom: "",
  uom1Factor1: "",
  uom2Factor1: "",
  grossWt: "",
  netWt: "",
  additionalDescription: "",
  printHtsCode: "",
  printUnitValue: "",
  packing9801HtsCode: "",
  packing9801UnitValue: "",
  usmcaCriteria: "",
  dateRangeFrom: "",
  dateRangeTo: "",
  isProducer: "",
  includeProdUsmca: "",
  lineitemOgaCode: [],
  lineitemAddiInfo: [],
  laceyAct: [],
  produce: null,
  usPacking: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: '8px 16px',
    width: (props) => props.width || theme.breakpoints.values.sm,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        flexGrow: 1
      }
    }
  }
}))(Paper)

function Fields({ disabled, shouldHideMap }) {
  const [formSettings, setFormSettings] = useState({ hideOptionalFields: false })
  const [formSettingsPopover, setFormSettingsPopover] = useState({ anchorEl: null })
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger, watch } = useFormContext()
  const [templateDialog, setTemplateDialog] = useState(false)
  const saveFlag = watch('saveFlag')
  const {
    partType,
    qtyUom,
    country,
    spclProgInd,
    bindRulingIdType,
    lineitemOgaCode,
    lineitemAddiInfo,
    hts9802Repair,
    usPacking,
    goodsreturned,
    laceyAct,
    fdaTab,
    fccTab,
    produce,
  } = useWatch([
    'partType',
    'qtyUom',
    'country',
    'spclProgInd',
    'bindRulingIdType',
    'lineitemOgaCode',
    'lineitemAddiInfo',
    'hts9802Repair',
    'usPacking',
    'goodsreturned',
    'laceyAct',
    'fdaTab',
    'fccTab',
    'produce',
  ])

  function makeTranslatedTextsObject() {
    let lineItem = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.TITLE
    )
    let partNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_NO
    )
    let htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.HTS_CODE
    )
    let partDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_DESC
    )
    let partType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_TYPE
    )
    let purchaseOrderNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PURCHASE_ORDER_NO
    )
    let partRefNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_REF_NO
    )
    let bindRulingIdType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.BIND_RULING_ID_TYPE
    )
    let bindRulingId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.BIND_RULING_ID
    )
    let spclProgInd = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.SPCL_PROG_IND
    )
    let unitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UNIT_VALUE
    )
    let country = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.COUNTRY
    )
    let qty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.QTY
    )
    let qtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.QTY_UOM
    )
    let uom1Factor1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UOM1_FACTOR1
    )
    let uom2Factor1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UOM2_FACTOR1
    )
    let grossWt = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.GROSS_WT
    )
    let netWt = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.NET_WT
    )
    let additionalDescription = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.ADDITIONAL_DESCRIPTION
    )
    let lineitemOgaCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.TITLE
    )
    let lineitemAddiInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemAddiInfo.TITLE
    )
    let hts9802Repair = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE
    )
    let usPacking = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.TITLE
    )
    let goodsreturned = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.TITLE
    )
    let laceyAct = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.TITLE
    )
    let fdaTab = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.TITLE
    )
    let fccTab = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.TITLE
    )
    let produce = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.TITLE
    )
    let saveFlag = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.SAVE_FLAG
    )
    let productId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PRODUCT_ID
    )
    let productDropDown = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PRODUCT_DROP_DOWN
    )

    return {
      lineItem,
      partNo,
      partDesc,
      partType,
      htsCode,
      qty,
      qtyUom,
      unitValue,
      uom1Factor1,
      grossWt,
      country,
      partRefNo,
      spclProgInd,
      purchaseOrderNo,
      bindRulingIdType,
      bindRulingId,
      uom2Factor1,
      netWt,
      additionalDescription,
      lineitemOgaCode,
      lineitemAddiInfo,
      hts9802Repair,
      usPacking,
      goodsreturned,
      laceyAct,
      fdaTab,
      fccTab,
      produce,
      saveFlag,
      productId,
      productDropDown,
    }
  }

  const columns = [
    {
      field: 'productId',
      title: translatedTextsObject.productId
    },
    {
      field: 'partType',
      title: translatedTextsObject.partType
    },
    {
      field: 'partNo',
      title: translatedTextsObject.partNo,
    },
    {
      field: 'productDesc',
      title: translatedTextsObject.partDesc
    }
  ]

  function handleSave(propertyName, data) {
    setValue(propertyName, data, { shouldValidate: true })
  }

  function handleApplyTemplate(template) {
    if(!template) return

    const {
      partNo,
      htsCode,
      productDesc,
      partType,
      purchaseOrderNo,
      country,
      partRefNo,
      bindRulingId,
      uom1Factor1,
      uom2Factor1,
      spclPrgInd,
      unitValue,
      bindRulingIdType,
      dateRangeFrom,
      dateRangeTo,
      isProducer,
      quantityUom,
      quantity,
      grossWt,
      netWt,
      ogaInfo,
      addiInfo,
      fccTab,
      fdaTab,
    } = template
  
    setValue('partNo', partNo || '')
    setValue('htsCode', htsCode || '')
    setValue('partDesc', productDesc || '')
    setValue('partType', partType || '')
    setValue('purchaseOrderNo', purchaseOrderNo || '')
    setValue('country', country || '')
    setValue('partRefNo', partRefNo || '')
    setValue('bindRulingId', bindRulingId || '')
    setValue('uom1Factor1', uom1Factor1 || '')
    setValue('uom2Factor1', uom2Factor1 || '')
    setValue('spclProgInd', spclPrgInd || '')
    setValue('unitValue', unitValue || '')
    setValue('bindRulingIdType', bindRulingIdType || '')
    setValue('dateRangeFrom', dateRangeFrom || '')
    setValue('dateRangeTo', dateRangeTo || '')
    setValue('isProducer', isProducer || '')
    setValue('qtyUom', quantityUom || '')
    setValue('qty', quantity || '')
    setValue('grossWt', grossWt || '')
    setValue('netWt', netWt || '')

    if (!_.isEmpty(ogaInfo)) {
      const lineitemOgaCode = ogaInfo.map((info) => ({
        ogaCode: info.ogaCode,
        ogaDocNo: info.ogaDocNo
      }))

      setValue('lineitemOgaCode', lineitemOgaCode)
    }

    if (!_.isEmpty(addiInfo)) {
      const lineitemAddiInfo = addiInfo.map((info) => ({
        additionalInfoType: info.additionalInfoType,
        additionalInfoNo: info.additionalInfoNo
      }))

      setValue('lineitemAddiInfo', lineitemAddiInfo)
    }

    if (fccTab) {
      const fccTabResult = {
        importConditionNo: fccTab.importConditionNo || '',
        fccIdentifier: fccTab.fccIdentifier || '',
        importCondition: fccTab.importCondition || '',
        fccRequirement: fccTab.fccRequirement || '',
        tradeName: fccTab.tradeName || '',
        withholdInspection: fccTab.withholdInspection || ''
      }

      setValue('fccTab', fccTabResult)
    }

    if (fdaTab) {
      const fdaAffirmation = fdaTab.fdaAffirmation.map((affirmation) => ({
        affirmationCode: affirmation.affirmationCode,
        affirmationQualifier: affirmation.affirmationQualifier
      }))
      const fdaQtyUom = fdaTab.fdaQtyUom.map((uom) => ({
        quantity: uom.quantity,
        uom: uom.uom
      }))

      const fdaTabResult = {
        productCode: fdaTab.productCode || '',
        storageStatus: fdaTab.storageStatus || '',
        country: fdaTab.country || '',
        manufacturerId: fdaTab.manufacturerId || '',
        shipperId: fdaTab.shipperId || '',
        marker: fdaTab.marker || '',
        fdaAffirmation: fdaAffirmation,
        fdaQtyUom: fdaQtyUom
      }

      setValue('fdaTab', fdaTabResult)
    }

    trigger()
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h4' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.lineItem}
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>
                          *
                        </Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid alignItems='stretch' container spacing={1}>
                        <Grid item xs={12} md>
                          <Grid alignItems='center' container spacing={1}>
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.saveFlag}>
                              <CngCheckboxField
                                label={
                                  <Typography style={{ fontSize: 12, lineHeight: 1.2 }}>
                                    {translatedTextsObject.saveFlag}
                                  </Typography>
                                }
                                name='saveFlag'
                                disabled={disabled}
                                onChange={(event) =>
                                  setValue('saveFlag', event.target.checked)
                                }
                                size='small'
                                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                                value={!saveFlag ? false : true}
                              />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productId}>
                              <CngTextField
                                name='productId'
                                label={translatedTextsObject.productId}
                                disabled={!saveFlag}
                                size='small'
                              />
                            </CngGridItem>
                          </Grid>
                        </Grid>
                        <Grid item xs='auto'>
                          <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs={12} md>
                          <SbciProductMasterAutoCompleteMasterField
                            name='productDropDown'
                            label={translatedTextsObject.productDropDown}
                            disabled={disabled}
                            onChange={(_, options) => handleApplyTemplate(options.data)}
                            size='small'
                            fullWidth
                            disableClearable
                            textFieldProps={{
                              InputProps: {
                                customEndAdornment: () => (
                                  <InputAdornment position='end' style={{ marginTop: -16 }}>
                                    <IconButton onClick={(event) => {
                                      event.stopPropagation()
                                      setTemplateDialog(true)
                                    }}>
                                      <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                        <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                                      </Box>
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            }}
                            forcePopupIcon={false}
                            lookupProps={{
                              filters: [{
                                field: 'partyId',
                                operator: EQUAL,
                                value: FileForUserGetPartyId()
                              }
                            ]
                            }}
                          />
                        </Grid>
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={(event) =>
                              setFormSettingsPopover({ anchorEl: event.currentTarget })
                            }
                            size='small'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              width={24}
                              height={24}
                            >
                              <FontAwesomeIcon icon={['fal', 'ellipsis']} />
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partNo}>
                      <CngTextField
                        name='partNo'
                        label={translatedTextsObject.partNo}
                        disabled={disabled}
                        onChange={(event) => setValue('partNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={9} shouldHide={shouldHideMap?.partDesc}>
                      <CngTextField
                        name='partDesc'
                        label={translatedTextsObject.partDesc}
                        disabled={disabled}
                        onChange={(event) => setValue('partDesc', event.target.value.toUpperCase(), {shouldValidate:true})}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partType}>
                      <CngCodeMasterAutocompleteField
                        codeType='SBCI_PART_TYPE'
                        key={partType}
                        name='partType'
                        label={translatedTextsObject.partType}
                        disabled={disabled}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.htsCode}>
                      <CngTextField
                        name='htsCode'
                        label={translatedTextsObject.htsCode}
                        disabled={disabled}
                        onChange={(event) => setValue('htsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.qty}>
                      <CngTextField
                        name='qty'
                        label={translatedTextsObject.qty}
                        disabled={disabled}
                        isRequired
                        size='small'
                        onChange={(event) => trigger('qty')}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.qtyUom}>
                      <CngUomAutocompleteField
                        key={qtyUom}
                        name='qtyUom'
                        label={translatedTextsObject.qtyUom}
                        disabled={disabled}
                        isRequired
                        size='small'
                        lookupProps={{
                          label: (record) => {
                            return `${record.code};${record.descriptionEn}`
                          },
                          filters: [
                            {
                              field: 'uomType',
                              operator: 'equal',
                              value: 'usb'
                            }
                          ]
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.unitValue}>
                      <CngTextField
                        name='unitValue'
                        label={translatedTextsObject.unitValue}
                        disabled={disabled}
                        isRequired
                        size='small'
                        onChange={(event) => trigger('unitValue')}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.uom1Factor1}>
                      <CngTextField
                        name='uom1Factor1'
                        label={translatedTextsObject.uom1Factor1}
                        disabled={disabled}
                        onChange={(event) => setValue('uom1Factor1', event.target.value.toUpperCase(), {shouldValidate:true})}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.grossWt}>
                      <CngTextField
                        name='grossWt'
                        label={translatedTextsObject.grossWt}
                        disabled={disabled}
                        isRequired
                        size='small'
                        onChange={(event) => trigger('grossWt')}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.country}>
                      <CngCountryAutocompleteField
                        key={country}
                        name='country'
                        label={translatedTextsObject.country}
                        disabled={disabled}
                        isRequired
                        size='small'
                        lookupProps={{
                          label: (record) => `${record.code};${record.descriptionEn}`
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partRefNo || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='partRefNo'
                        label={translatedTextsObject.partRefNo}
                        disabled={disabled}
                        onChange={(event) => setValue('partRefNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.spclProgInd || formSettings.hideOptionalFields}>
                      <CngCodeMasterAutocompleteField
                        codeType='SBCI_SPECIAL_PROGRAM_IND'
                        key={spclProgInd}
                        name='spclProgInd'
                        label={translatedTextsObject.spclProgInd}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.purchaseOrderNo || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='purchaseOrderNo'
                        label={translatedTextsObject.purchaseOrderNo}
                        disabled={disabled}
                        onChange={(event) => setValue('purchaseOrderNo', event.target.value.toUpperCase(), {shouldValidate:true})}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.bindRulingIdType || formSettings.hideOptionalFields}>
                      <CngCodeMasterAutocompleteField
                        codeType='SBCI_BIND_RULING_ID_TYPE'
                        key={bindRulingIdType}
                        name='bindRulingIdType'
                        label={translatedTextsObject.bindRulingIdType}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.bindRulingId || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='bindRulingId'
                        label={translatedTextsObject.bindRulingId}
                        disabled={disabled}
                        onChange={(event) => setValue('bindRulingId', event.target.value.toUpperCase(), {shouldValidate:true})}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.uom2Factor1}>
                      <CngTextField
                        name='uom2Factor1'
                        label={translatedTextsObject.uom2Factor1}
                        disabled={disabled}
                        onChange={(event) => setValue('uom2Factor1', event.target.value.toUpperCase(), {shouldValidate:true})}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.netWt || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='netWt'
                        label={translatedTextsObject.netWt}
                        disabled={disabled}
                        size='small'
                        onChange={(event) => trigger('netWt')}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.additionalDescription || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='additionalDescription'
                        label={translatedTextsObject.additionalDescription}
                        disabled={disabled}
                        onChange={(event) => setValue('additionalDescription', event.target.value.toUpperCase(), {shouldValidate:true})}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CollapsibleSection
                    defaultExpanded={!_.isEmpty(lineitemOgaCode)}
                    onExpandedChange={(expanded) => {
                      const { initialValues } = LineitemOgaCodeFormProperties.formikProps
                      setValue('lineitemOgaCode', expanded ? [{ ...initialValues, _id: 1 }] : [])
                    }}
                    title={translatedTextsObject.lineitemOgaCode}
                  >
                    <OgaCodeSection lineitemOgaCode={lineitemOgaCode} />
                  </CollapsibleSection>
                </Grid>
                <Grid item xs={12}>
                  <CollapsibleSection
                    defaultExpanded={!_.isEmpty(lineitemAddiInfo)}
                    onExpandedChange={(expanded) => {
                      const { initialValues } = LineitemAddiInfoFormProperties.formikProps
                      setValue('lineitemAddiInfo', expanded ? [{ ...initialValues, _id: 1 }] : [])
                    }}
                    title={translatedTextsObject.lineitemAddiInfo}
                  >
                    <AddiInfoSection lineitemAddiInfo={lineitemAddiInfo} />
                  </CollapsibleSection>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h4' style={{ fontWeight: 600 }}>
                        Declarations
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>
                          *
                        </Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Hts9802RepairSection
                        hts9802Repair={hts9802Repair}
                        onSave={(data) => handleSave('hts9802Repair', data)}
                        title={translatedTextsObject.hts9802Repair}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UsPackingSection
                        usPacking={usPacking}
                        onSave={(data) => handleSave('usPacking', data)}
                        title={translatedTextsObject.usPacking}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <GoodsReturnedSection
                        goodsreturned={goodsreturned}
                        onSave={(data) => handleSave('goodsreturned', data)}
                        title={translatedTextsObject.goodsreturned}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LaceyActSection
                        laceyAct={laceyAct}
                        onSave={(data) => handleSave('laceyAct', data)}
                        title={translatedTextsObject.laceyAct}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FdaTabSection
                        fdaTab={fdaTab}
                        onSave={(data) => handleSave('fdaTab', data)}
                        title={translatedTextsObject.fdaTab}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FccTabSection
                        fccTab={fccTab}
                        onSave={(data) => handleSave('fccTab', data)}
                        title={translatedTextsObject.fccTab}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProduceSection
                        produce={produce}
                        onSave={(data) => handleSave('produce', data)}
                        title={translatedTextsObject.produce}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <FormSettingsPopover
        anchorEl={formSettingsPopover.anchorEl}
        open={formSettingsPopover.anchorEl ? true : false}
        onClose={() => setFormSettingsPopover({ anchorEl: null })}
        settings={formSettings}
        onChangeSettings={(setting) => setFormSettings((prev) => ({ ...prev, ...setting }))}
      />
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'productId', direction: 'ASC' }]}
        fetch={{ url: SbciProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }} //custom field name for BE to custom search
        title='Template'
      />
    </>
	)
}

function FormSettingsPopover(props) {
  const { anchorEl, open, onClose, settings, onChangeSettings } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverWrapper width={300}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={settings.hideOptionalFields}
              edge='end'
              onChange={(event) =>
                onChangeSettings({ hideOptionalFields: event.target.checked })
              }
            />
          }
          label='Hide optional fields'
          labelPlacement='start'
        />
      </StyledPopoverWrapper>
    </Popover>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  if (!_.isEmpty(localData.laceyAct)) {
    localData.laceyAct.forEach((act) => {
      act.pgaCodes.split('|').forEach((pgaCode, index) => {
        act[`pga${index + 1}`] = pgaCode
      })
    })
  }

  return localData
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }

  if (!_.isEmpty(serverData.laceyAct)) {
    serverData.laceyAct.forEach((act) => {
      const { pga1, pga2, pga3, pga4, pga5, pga6 } = act

      const pgaCodes = [pga1, pga2, pga3, pga4, pga5, pga6]
        .filter((pgaCode) => pgaCode)
        .join('|')

      act['pgaCodes'] = pgaCodes
    })
  }

  if (serverData.hts9802Repair) {
    let { reworkDate } = serverData.hts9802Repair

    reworkDate = DateTimeFormatter.toServerDate(reworkDate)
  }

  if (serverData.fccTab) {
    let { importCondition, withholdInspection } = serverData.fccTab

    importCondition = importCondition ? 'Y' : 'N'
    withholdInspection = withholdInspection ? 'Y' : 'N'
  }

  return serverData
}

const LineItemFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default LineItemFormProperties
